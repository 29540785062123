<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="container-sm fn text-white p-0">
      <div class="mt-3">
        <div class="marquee p-0">
          <marquee
            behavior="asdasd"
            direction=""
          >
            <p
              v-if="BetflixStatus === 'error'"
              style="color: red"
            >
              Betflix ปิดปรับปรุงเว็บไซต์ชั่วคราว ขออภัยในความไม่สะดวก
            </p>
            <p
              v-else
              style="color: black; font-weight: 300;"
            >
              ขณะนี้เปิดให้บริการตามปกติแล้วค่ะ | Maintenance Completed
            </p>
            <!-- ระบบกำลังปิดปรับปรุง กรุณารอสักครู่นะคะ | Under Maintenance -->
          </marquee>
        </div>
      </div>

      <div class="mt-2 text-center">
        <swiper
          class="swiper-paginations"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide
            v-for="(data,index) in swiperData"
            :key="index"
            class="game_card"
          >
            <b-img
              :src="data.img"
              class="game_image"
              fluid
            />
          </swiper-slide>
        </swiper>
        <div class="mt-1">
          <span style="color: #F07F2F">กระเป๋าของฉัน</span>
        </div>
        <h2 class="font-large-1 balance mb-0">
          {{

            Number(Math.abs(balance))
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ','),

          }}
        </h2>
      </div>
      <div class="text-center">
        <span
          style="font-size: 0.95rem; color: #F07F2F"
          @click="refresh"
        ><i
          class="fad fa-redo-alt"
          style="font-size: 15px"
        />&nbsp;&nbsp;ข้อมูล ณ เวลา {{ timenow }} น.</span>
      </div>

      <!-- <div class="demo-inline-spacing"> -->
      <!-- <button
        block
        class="m-0 btn"
      >
        รับแคชแบ็ก ฿{{
          Number(balance.balance)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }}
      </button> -->
      <!-- </div> -->
      <div class="mt-2">
        <b-row class="text-center">
          <b-col
            md="3"
            cols="3"
            class="cursor-pointer"
          >
            <router-link :to="{ name: 'deposit' }">
              <div class="text-center">
                <img
                  src="/iconNew/icon-3xl-01.png"
                  alt="deposit"
                  height="80"
                >
                <div class="menu-text">
                  ฝากเงิน
                </div>
              </div>
            </router-link>
          </b-col>
          <b-col
            md="3"
            cols="3"
          >
            <router-link :to="{ name: 'withdraw' }">
              <div class="text-center">
                <img
                  src="/iconNew/icon-3xl-02.png"
                  alt="deposit"
                  height="80"
                >
                <div class="menu-text">
                  ถอนเงิน
                </div>
              </div>

            </router-link>
          </b-col>
          <b-col
            md="3"
            cols="3"
          >
            <router-link :to="{ name: 'promotions' }">
              <div class="text-center">
                <img
                  src="/iconNew/icon-3xl-03.png"
                  alt="deposit"
                  height="80"
                >
                <div class="menu-text">
                  โปรโมชั่น
                </div>
              </div>
            </router-link>
          </b-col>
          <b-col
            md="3"
            cols="3"
          >
            <router-link :to="{ name: 'profile' }">
              <div class="text-center">
                <img
                  src="/iconNew/icon-3xl-04.png"
                  alt="deposit"
                  height="80"
                >
                <div class="menu-text">
                  โปรไฟล์
                </div>
              </div>
            </router-link>
          </b-col>
        </b-row>

        <b-row
          class="text-center"
          style="margin-top: 20px"
        >
          <b-col
            md="3"
            cols="3"
            class="cursor-pointer"
          >
            <router-link :to="{ name: 'fixed-deposit' }">
              <div class="text-center">
                <img
                  src="/iconNew/icon-3xl-07.png"
                  alt="deposit"
                  height="80"
                >
                <div class="menu-text">
                  ฝากประจำ
                </div>
              </div>
            </router-link>
          </b-col>
          <b-col
            md="3"
            cols="3"
          >
            <router-link :to="{ name: 'invite-friend' }">
              <div class="text-center">
                <img
                  src="/iconNew/icon-3xl-05.png"
                  alt="deposit"
                  height="80"
                >
                <div class="menu-text">
                  ชวนเพื่อน
                </div>
              </div>
            </router-link>
          </b-col>
          <b-col
            md="3"
            cols="3"
          >
            <router-link :to="{ name: 'history' }">
              <div class="text-center">
                <img
                  src="/iconNew/icon-3xl-06.png"
                  alt="deposit"
                  height="80"
                >
                <div class="menu-text">
                  ประวัติ
                </div>
              </div>
            </router-link>
          </b-col>
          <b-col
            md="3"
            cols="3"
          >
            <div class="cursor-pointer">
              <a :href="Agent.linecontact">
                <div class="text-center">
                  <img
                    src="/iconNew/icon-3xl-08.png"
                    alt="deposit"
                    height="80"
                  >
                  <div class="menu-text">
                    ติดต่อเรา
                  </div>
                </div>
              </a>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- <b-row v-if="turnpro">
        <b-col
          md="12"
          class="mt-2"
        >
          <app-collapse>
            <app-collapse-item title="สถานะโปรโมชั่น">
              <div class="align-items-center">
                <hr>
                <b-row>
                  <b-col cols="6">
                    ชื่อโปรโมชั่น
                  </b-col>
                  <b-col cols="6">
                    {{ turnpro.promotion }}
                  </b-col>
                  <b-col cols="6">
                    ยอดเงินที่ต้องทำ
                  </b-col>
                  <b-col cols="6">
                    ฿{{ turnpro.turnover }}
                  </b-col>
                  <b-col cols="6">
                    ยอดเงินปัจจุบัน
                  </b-col>
                  <b-col cols="6">
                    ฿{{ Number(Math.abs(balance))
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','), }}
                  </b-col>
                  <b-col cols="6">
                    ต้องทำยอดเงินอีก
                  </b-col>
                  <b-col cols="6">
                    ฿{{ Number(turnpro.turnover) - Number(balance) }}
                  </b-col>
                </b-row>
                <b-card
                  class="text-center"
                  style="
                    background: #d91f20;
                    border-radius: 15px;
                    color: #fff;
                    font-size: 1.1rem;
                  "
                >
                  ท่านต้องทำเงินให้ได้ "มากกว่า หรือ เท่ากับ" ฿{{
                    turnpro.turnover
                  }}
                  จึงจะ สามารถถอนได้
                </b-card>
              </div>
            </app-collapse-item>
          </app-collapse>
          <br>
          <br><br><br><br>
        </b-col>
      </b-row> -->
    </div>
  </b-overlay>
</template>

<script>
import {
  // BCard,
  BRow,
  BCol,
  BImg,
  // BLink,
  BOverlay,
  BIconController,
  // BFormCheckbox,
  // BButton,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
// import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import BUID from 'uniquebrowserid'
import 'swiper/css/swiper.css'

export default {
  components: {
    // BCard,
    BRow,
    BCol,
    BImg,
    // BLink,
    Swiper,
    SwiperSlide,
    BOverlay,
    BIconController,
    // AppCollapseItem,
    // AppCollapse,

    // BFormCheckbox,
    // BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
    const toast = useToast()
    const showToast = () => toast.success('คัดลอก')

    return { showToast }
  },
  data() {
    return {
      show: true,
      balance: 0.0,
      turnpro: '',
      userData: JSON.parse(localStorage.getItem('userData')),
      today: moment(new Date()).format('DD/MM/YYYY'),
      timenow: moment(new Date()).format('HH:mm'),
      message: 'asdasd',
      User: '',
      BetflixStatus: '',
      Agent: '',
      /* eslint-disable global-require */
      swiperData: [
        { img: require('@/assets/images/banner/3xl789 02.jpg') },
        { img: require('@/assets/images/banner/3xl789 04.jpg') },
        { img: require('@/assets/images/banner/3xl789 06.jpg') },
        { img: require('@/assets/images/banner/3xl789 08.jpg') },
      ],
      /* eslint-disable global-require */

      swiperOptions: {
        centeredSlides: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },
    }
  },
  mounted() {
    // this.openModal()
    this.getbalance(this.userData.username)
    this.getturn()
    this.getUser(this.userData)
    this.updateBUID()
    this.getBetflixStatus()
    this.getAg()
  },
  methods: {
    getBetflixStatus() {
      this.show = true
      this.$http
        .get('https://api.ufabangkok.com/api/play/betflixStatus')
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.BetflixStatus = response.data
          if (this.BetflixStatus === 'error') {
            this.SwalError(
              'Betflix ปิดปรับปรุงเว็บไซต์ชั่วคราว ขออภัยในความไม่สะดวก',
            )
          }
        })
        .catch(error => console.log(error))
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color:#000">ขออภัยค่ะ!<h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    updateBUID() {
      const buid = new BUID().completeID()
      const formData = {
        buid,
      }
      this.$http.post('https://api.ufabangkok.com/api/users/updatebuid', formData)
    },
    getUser(userData) {
      this.show = true
      this.$http
        .get(`https://api.ufabangkok.com/api/users/show/${userData.userID}`)
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.User = response.data
        })
        .catch(error => console.log(error))
    },
    showAlert() {
      this.$swal({
        icon: 'question',
        title: '<h3 style="color: #000">จะเปิดให้บริการ เร็วๆนี้</h3>',
        customClass: {
          confirmButton: 'btn btn-warning',
        },
        buttonsStyling: false,
      })
    },
    onCopy() {
      this.toast('b-toaster-bottom-center', true)
    },
    onError() {
      this.toast('b-toaster-bottom-center', true)
    },
    doCopy() {
      this.$copyText('ssssss').then(
        () => {
          console.log('success')
        },
        e => {
          console.log(e)
        },
      )
    },
    getbalance(username) {
      this.show = true
      const params = {
        username,
      }
      this.$http
        .get('https://api.ufabangkok.com/api/balance/getbalance', { params })
        .then(response => {
          this.show = false
          this.balance = response.data.data.balance
          if (this.balance < 0) {
            this.balance = 0
          }
        })
        .catch(error => console.log(error))
    },
    getturn() {
      this.show = true
      this.$http
        .get('https://api.ufabangkok.com/api/promotion/getturnpro')
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.turnpro = response.data
        })
        .catch(error => console.log(error))
    },
    refresh() {
      this.getbalance(this.userData.username)
      // this.today = moment(new Date()).format('DD/MM/YYYY')
      // this.timenow = moment(new Date()).format('HH:mm')
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    openModal() {
      this.$refs['my-pro'].show()
    },
    closeModal() {
      this.$refs['my-pro'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    makeToast(variant = null) {
      this.$bvToast.toast('คัดลอกเลขบัญชีเรียบร้อย', {
        title: 'หมายเลขบัญชี',
        variant,
        solid: true,
      })
    },
    toast(toaster, append = false) {
      this.$bvToast.toast('คัดลอกเลขบัญชีเรียบร้อย', {
        title: 'หมายเลขบัญชี',
        toaster,
        solid: true,
        appendToast: append,
      })
    },
    getAg() {
      this.$http
        .get('https://api.ufabangkok.com/api/agent/show')
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.Agent = response.data
          this.message = this.Agent.truemoney_acc
        })
        .catch(error => console.log(error))
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
i {
  color: #ffc800;
  font-size: 25px;
}
.game_card {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 10px;
    padding: 5px;
}
.game_image {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
}
img {
    vertical-align: middle;
    border-style: none;
}
.menu-left-btn {
  margin: auto;
    background: linear-gradient(to bottom, #393939, #292929);
    box-shadow: 0 5px 7px 0 #000000;
    border-radius: 15px;
    width: 66px;
    height: 60px;
    text-align: center;
    flex-wrap: wrap;
    padding: 7px 0;
    font-weight: bold;
    font-family: var(--systemfont);
    cursor: pointer;
}
.menu-text {
  color: #333333;
    margin-top: 3px;
    font-weight: 400;
    font-size: 12px;
}
.all_message {
  background: linear-gradient(to bottom, #929292, #5f5f5f);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 0 15px;
  color: #000;
  height: 20px;
  overflow: hidden;
  width: 20%;
  float: right;
  position: relative;
  top: -20px;
}
.txtall_message {
  position: relative;
  font-size: 15px;
  color: #000 !important;
  white-space: nowrap;
  top: -6px;
  left: -5px;
}
.marquee {
  /* background: rgb(255, 247, 1);
  background: linear-gradient(
    22deg,
    rgba(255, 247, 1, 1) 0%,
    rgba(255, 8, 1, 1) 100%
  ); */
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  padding: 0 15px;
  text-align: center;
  color: #fff;
  height: 20px;
  font-size: 16px;
  overflow: hidden;
}
.btn {
  border-radius: 38px;
  width: 100vw;
  color: #fff !important;
  background-image: linear-gradient(249deg, #ff00db, #8726ff);
}
/* .balance-cl {
  margin: auto;
  width: 230px;
  height: 230px;
  padding: 24px;
  box-sizing: border-box;
  color: #000;
  border: 8px solid transparent;
  background-image: url(/Low/bg3.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
} */
.menu-mobile__icon {
  width: 100%;
  background-color: #0f0f0f;
  position: relative;
  padding-top: 100%;
  border-radius: 20px;
}
.menu-mobile {
  -webkit-box-flex: 0;
  max-width: 25%;
  flex: 0 0 25%;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  margin-bottom: 24px;
  display: block;
}
/* .fn {
  font-family: 'Helvethaica', sans-serif;
} */
.container-sm {
  max-width: 540px;
}
span {
  color: rgb(255, 255, 255);
  font-size: 1rem;
  /* background: #0b0e11; */
  /* border-radius: 5px;
  padding: 5px 5px; */
}
.m-space {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 35px;
  margin-left: 1px;
  margin-right: 1px;
}
a {
  color: #fff;
  text-decoration: none;
}
a:hover {
  color: #ffc800;
  text-decoration: none;
}
.balance {
  margin: 0px;
  line-height: 1;
  font-size: 1.82rem;
  color: rgb(0, 0, 0);
  /* text-shadow: rgb(255 188 0) 1px 0px 17px; */
  font-family: 'Rubik', sans-serif;
}
/* .cs {
  background: linear-gradient(to bottom, #7222416e, #58585870);
  border-radius: 10px;
  padding: 0.5rem;
  border: rgba(255, 255, 255, 0.527) solid 4px;
  box-shadow: #8826ff94 0px 5px 10px 5px;
} */
</style>

<style lang="scss" scoped>
.card-body {
  padding: 0.5rem;
}
</style>
